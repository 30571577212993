import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../layout";
import SEO from "../../components/SEO/SEO";
import config from "../../../data/SiteConfig";
import "./CategorizedList.css";
import PostPreview from "../../components/PostPreview/PostPreview";
import EmptyMessaging from "../../components/EmptyMessaging/EmptyMessaging";

function CategorizedList({ pageContext, data }) {
  const { category, currentPageNum, pageCount } = pageContext;
  const postEdges = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <div className="listing-container">
          <Helmet title={config.siteTitle} />
          <SEO />
          { postEdges.length === 0 &&
            <EmptyMessaging/>
          }
          { postEdges.length > 0 &&
            <PostPreview postEdges={postEdges} heading={category}/>
          }
      </div>
    </Layout>
  );
}

export default CategorizedList;

/* eslint no-undef: "off" */
export const categorizedListQuery = graphql`
  query CategorizedListQuery($skip: Int!, $limit: Int!, $category: String!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            cover {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED 
                  height:350
                )
              }
            }
            coverAlt
            date
            category
          }
        }
      }
    }
  }
`;
